import { getAccessToken } from '@/api/user';
import store from '@/store';
import axios from 'axios'
import { Message } from 'element-ui';
// axios.defaults.timeout = 30000;
// axios.defaults.baseURL = 'api/';

// 定义一个变量，用于标记 token 刷新的状态
let isRefreshing = false;
let refreshSubscribers = [];

// request拦截
axios.interceptors.request.use(
    config => {
        let access_token = store.state.access_token;
        const isToken = (config.headers || {}).isToken
        // 不需要token
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

//  response拦截
axios.interceptors.response.use(
    async (res) => {
        console.log(res,666)
        if (res.status !== 200 || res.data.code === 1) {
            const text = res.data.msg
            Message({ message: text, type: 'error' });
            return Promise.reject(res);
        } else if (res.data.code === 0) {
            return Promise.resolve(res);
        } else if (res.data.code === 10005) {
            // access_token过期

            if (!isRefreshing) {
                isRefreshing = true;
                // 发起刷新 token 的请求
                const resule = await getAccessToken();
                isRefreshing = false
                const newToken = resule.data.data.access_token
                store.commit('setToken', resule.data.data.access_token)

                // 刷新 token 完成后，重新发送之前失败的请求
                res.config.data = {
                    ...JSON.parse(res.config.data),
                    access_token: newToken
                }
                refreshSubscribers.forEach((cb) => cb(newToken));
                refreshSubscribers = [];
                return axios(res.config);
            } else {
                // 返回未执行 resolve 的 Promise
                return new Promise((resolve) => {
                    refreshSubscribers.push((newToken) => {
                        // 刷新 token 完成后，重新发送之前失败的请求
                        res.config.data = {
                            ...JSON.parse(res.config.data),
                            access_token: newToken
                        }
                        // 用函数形式将 resolve 存入，等待刷新后再执行
                        resolve(axios(res.config));
                    });
                });
            }
        } else if (res.data.code === 10001) {
            // access_token为空
            if (!isRefreshing) {
                isRefreshing = true;
                // 发起刷新 token 的请求
                const resule = await getAccessToken();
                isRefreshing = false
                const newToken = resule.data.data.access_token
                store.commit('setToken', resule.data.data.access_token)

                // 刷新 token 完成后，重新发送之前失败的请求
                res.config.data = {
                    ...JSON.parse(res.config.data),
                    access_token: newToken
                }
                refreshSubscribers.forEach((cb) => cb(newToken));
                refreshSubscribers = [];
                return axios(res.config);
            } else {
                // 返回未执行 resolve 的 Promise
                return new Promise((resolve) => {
                    refreshSubscribers.push((newToken) => {
                        // 刷新 token 完成后，重新发送之前失败的请求
                        res.config.data = {
                            ...JSON.parse(res.config.data),
                            access_token: newToken
                        }
                        // 用函数形式将 resolve 存入，等待刷新后再执行
                        resolve(axios(res.config));
                    });
                });
            }
        }
        return res
    },
    error => {
        if (error.code == "ERR_BAD_RESPONSE") {
            Message({ message: error.message, type: 'error' });
        }
        return Promise.reject(error);
    }
);

export default axios;
